import React from 'react'
import Galleries from '../../components/galleries/galleries.js'
import Layout from '../../layouts/index.js'

const array = [
  '007591',
  '007592',
  '007614',
  '007640',
  '007645',
  '007692',
  '007706',
  '007726',
  '007762',
  '007782',
  '007798',
  '007821',
  '007848',
  '008306',
  '008343',
  '008357',
  '008362',
  '008379',
]

const images = array.map((image) => {
  return (
    <figure>
      <img
        alt=""
        key={image}
        src={require('../../images/gallery/emperor-penguins/' + image + '.jpg')}
      />
    </figure>
  )
})

const EmperorPenguins = () => (
  <Layout>
    <section>
      <h1>Emperor Penguins</h1>
      {images}
      <Galleries />
    </section>
  </Layout>
)

export default EmperorPenguins
